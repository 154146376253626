import React from 'react';
//import ReactDOM from 'react-dom';
import { Link } from "gatsby"
import "./tile.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class InternalTile extends React.Component {
    render() {
        return <Link to={this.props.link}>
                <div className="tile" style={{backgroundColor: this.props.color}}>
                    <FontAwesomeIcon className="icon" icon={this.props.iconType} size="5x"/>
                    <span className="description">{this.props.title}</span>
                </div>
            </Link>;
    }
}

class ExternalTile extends React.Component {
    render() {
        return <a href={this.props.link}>
                <div className="tile" style={{backgroundColor: this.props.color}}>
                    <FontAwesomeIcon className="icon" icon={this.props.iconType} size="5x"/>
                    <span className="description">{this.props.title}</span>
                </div>
            </a>;
    }
}

export {
    InternalTile,
    ExternalTile
  }