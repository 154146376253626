import React from "react"
//import { Link } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Layout from "../components/layout"
//import Image from "../components/image"
import SEO from "../components/seo"
import { InternalTile, ExternalTile } from "../components/tile"
import { faUser, faBriefcase, faPhone } from '@fortawesome/free-solid-svg-icons'
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons'


const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Row>
      <Col>
      <p>Hello! I'm James, a web developer from London. I studied Computer Science at
        the <a href="//www.kent.ac.uk">University of Kent</a> in Canterbury before moving
        to <a href="//www.holidayextras.co.uk">Holiday Extras</a> and progressing to
        my current web development role at <a href="//www.qvcuk.com">QVC</a>, the
        UK's largest multichannel retailer broadcasting 24 hours a day, 365 days a year to over 26 million homes in the UK.
        I have a strong knowledge of web and mobile development (including HTML5, CSS3, JavaScript, React, and React Native) and
        a solid understanding of system design and architecture providing the ability to translate functional
        requirements into joyful user experiences.
      </p>
      <p>Thanks for visiting and feel free to get in touch.</p>
      </Col>
    </Row>
    <Row className="internalLinks">
      <Col xs={12} md={4} className="mt-3 my-md-0"><InternalTile title="CV" iconType={faUser} link="/cv/" color="#3387ea"/></Col>
      <Col xs={12} md={4} className="mt-3 my-md-0"><InternalTile title="Portfolio" iconType={faBriefcase} link="/portfolio/" color="#f9be3e"/></Col>
      <Col xs={12} md={4} className="mt-3 my-md-0"><InternalTile title="Contact" iconType={faPhone} link="/contact/" color="#d3573e"/></Col>
    </Row>
    <Row className="social mt-md-4">
      <Col xs={6} md={3} className="mt-3 my-md-0 pr-2 pr-md-3"><ExternalTile iconType={faFacebookF} link="//www.facebook.com/jtarmitage" color="#3b5998"/></Col>
      <Col xs={6} md={3} className="mt-3 my-md-0 pl-2 pl-md-3"><ExternalTile iconType={faTwitter} link="//twitter.com/j_armitage" color="#2daae1"/></Col>
      <Col xs={6} md={3} className="mt-3 my-md-0 pr-2 pr-md-3"><ExternalTile iconType={faInstagram} link="//instagram.com/j_armitage" color="#3f729b"/></Col>
      <Col xs={6} md={3} className="mt-3 my-md-0 pl-2 pl-md-3"><ExternalTile iconType={faLinkedinIn} link="//www.linkedin.com/in/jtarmitage/" color="#0073b2"/></Col>
    </Row>
  </Layout>
)

export default IndexPage
